import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const Success = ({ status, plan }) => {

    const downloadInvoice = () => {
        const invoice = document.getElementById('invoice');

        html2canvas(invoice, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');

            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save(`Invoice_${status.order_id}.pdf`);
        });
    };

    return (
        <>
            <section className="PaymentData clearfix">
                <div className='container'>
                    <div className='payment-receipt'>
                        <div id="invoice" className='px-4 py-3'>
                            <div className='text-center mb-3'>
                                <img src='assets/img/success.png' alt='success' />
                                <p className='mt-2 p-0 fw-bold'>PURCHASE SUCCESSFULL!</p>
                            </div>
                            <hr style={{ color: 'rgba(0,0,0,0.3)' }} />
                            <div className='amoput-details'>
                                <span>Amount</span>
                                <h2>{status.order_currency} {status.order_amount}</h2>
                            </div>
                            <p className='text-center my-3 p-0'>You have successfully purchase the credits</p>
                            <hr style={{ color: 'rgba(0,0,0,0.3)' }} />
                            <div className='transaction-data'>
                                <div className='d-flex justify-content-between mb-2'>
                                    <span>Order ID</span>
                                    <b >{status.order_id}</b>
                                </div>
                                <div className='d-flex justify-content-between mb-2'>
                                    <span>Date</span>
                                    <b>{status.created_at.split('+')[0].replace('T', ' ')}</b>
                                </div>
                                <div className='d-flex justify-content-between mb-2'>
                                    <span>Plan Type</span>
                                    <b >{plan.plan_name}</b>
                                </div>
                                <div className='d-flex justify-content-between mb-2'>
                                    <span>Credits</span>
                                    <b >{plan.credits}</b>
                                </div>
                            </div>
                            <hr style={{ color: 'rgba(0,0,0,0.3)' }} />
                            <div className='transaction-data'>
                                <div className='d-flex justify-content-between mb-2'>
                                    <span>Name</span>
                                    <b >{status.customer_details.customer_name}</b>
                                </div>
                                <div className='d-flex justify-content-between mb-2'>
                                    <span>Mobile</span>
                                    <b >{status.customer_details.customer_phone}</b>
                                </div>
                                <div className='d-flex justify-content-between mb-2'>
                                    <span>Email</span>
                                    <b >{status.customer_details.customer_email}</b>
                                </div>
                            </div>
                            <div className="text-center mt-4">
                                <p className='p-0 mb-2'>Thank you for your purchase! If you have any questions, contact us at support@deletebg.com.</p>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <button className="pay-btn d-flex align-items-center justify-content-center w-100" onClick={downloadInvoice}><DownloadOutlined style={{ fontSize: 20, marginRight: 8 }} />  Download Invoice</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Success