import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Header from '../Header';
import Footer from '../Footer';
import Success from './Success';
import Pending from './Pending';
import useStorage from '../../use/useStorage';

const PaymentStatus = () => {
    const [cache] = useStorage({
        plans: [],
    });

    const [plan, setPlan] = useState([]);
    const [status, setStatus] = useState(null);
    const { order_id } = useParams();

    useEffect(() => {
        const fetchStatus = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/get-order`, {
                    params: { order_id },
                });
                console.log("order status: ", response);

                setStatus(response.data);
                setPlan(cache.plans.find(e =>
                    e.monthly_price === response.data.order_amount.toFixed(2) || e.yearly_price === response.data.order_amount.toFixed(2)
                ));
            } catch (error) {
                console.error('Error fetching payment status:', error);
            }
        };

        if (order_id) {
            fetchStatus();
        }
    }, [order_id]);


    if (!status) {
        return (
            <>
                <Header />

                <h1>Loading...</h1>

                <Footer />
            </>
        );
    }

    return (
        <>
            <Header />

            {status.order_status === "PAID" && <Success status={status} plan={plan} />}
            {status.order_status === "ACTIVE" && <Pending status={status} plan={plan} />}

            <Footer />
        </>
    );
};

export default PaymentStatus;