import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import store from '../../utils/store'
import { confirmModal } from '../../utils/functions'
import { FileSyncOutlined, HistoryOutlined, HomeOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons'
import Account from './Account'
import Orders from './Orders'
import Refunds from './Refunds'

const Dashboard = () => {

    const [tab, setTab] = useState("account")
    const [component, setComponent] = useState(null);

    useEffect(() => {
        let component = null;
        switch (tab) {
            case "account":
                component = <Account />
                break;
            case "orders":
                component = <Orders />
                break;
            case "refunds":
                component = <Refunds />
                break;
            default:
                break;
        }
        setComponent(component);
    }, [tab])

    const handleLogout = async () => {
        const title = 'Logout ?';
        const content = <div style={{ textAlign: 'center' }}>Are you sure you want to log out ?</div>;
        const confirmation = await confirmModal(title, content, 416);
        if (confirmation) {
            store.set({ profile: null });
            window.location.reload();
        }
    }

    return (
        <>
            <div className='dashboard'>
                <div className='dashboard-side'>
                    <div className='side-navbar'>
                        <div className='side-logo'>
                            <Link to="/"><img src="assets/img/db174x48.png" alt="logo" /></Link>
                        </div>
                        <ul>
                            <li className={tab === "account" ? "active" : null} onClick={() => setTab("account")}><UserOutlined /> Account</li>
                            <li className={tab === "orders" ? "active" : null} onClick={() => setTab("orders")}><HistoryOutlined /> Orders</li>
                            <li className={tab === "refunds" ? "active" : null} onClick={() => setTab("refunds")}><FileSyncOutlined /> Refunds</li>
                        </ul>
                    </div>
                    <div className='side-footer'>
                        <Button type='primary' danger onClick={handleLogout}><LogoutOutlined /> Logout</Button>
                    </div>
                </div>
                <div className='dashboard-content'>
                    <div className='content-header'>
                        <Link to="/"><button className="gradient-btn"><HomeOutlined /></button></Link>
                    </div>
                    <div className='content-main'>
                        {component}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard