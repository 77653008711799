import axios from 'axios';
import { getCurrentTimestamp } from '../dropbox';

const baseUrl = "https://img.deletebg.com"
// const baseUrl = "https://api.deletebg.com"
// const baseUrl = "http://localhost:5000"

export const performBackgroundRemoval = async (inputURL, outputURL, token) => {
    const url = `${baseUrl}/background-removal`;

    console.log("inputURL: ", inputURL, getCurrentTimestamp());
    console.log("outputURL: ", outputURL, getCurrentTimestamp());

    try {
        const response = await axios.post(url,
            { input: { storage: 'external', href: inputURL }, output: { storage: 'dropbox', href: outputURL, mask: { format: "soft" } } },
            { headers: { Authorization: `Bearer ${token}`, 'x-api-key': process.env.REACT_APP_ADOBE_API_KEY, 'Content-Type': 'application/json' } }
        );
        return response.data;
    } catch (error) {
        console.log(error);
        throw error.response.data || error.message;
    }
}

export const generateIMSToken = async () => {
    const url = `${baseUrl}/get-ims-token`;
    const data = new URLSearchParams();
    data.append('grant_type', 'client_credentials');
    data.append('client_id', process.env.REACT_APP_ADOBE_API_KEY);
    data.append('client_secret', process.env.REACT_APP_ADOBE_CLIENT_SECRET);
    data.append('scope', 'openid,AdobeID,read_organizations');

    try {
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        console.log("Adobe_token_response: ", response, getCurrentTimestamp());
        return response.data.access_token;
    } catch (error) {
        throw error.response.data || error.message;
    }
}

export const getInputURL = async (imageData) => {
    const formData = new FormData();
    formData.append('file', imageData);

    try {
        const response = await axios.post(`${baseUrl}/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data.inputURL;
    } catch (error) {
        console.error('Error uploading file:', error);
    }
}

export const getImageUrl = () => {
    const processedImageName = localStorage.getItem('processedImageName');
    return `${baseUrl}/processed/${processedImageName}`
};

export const getBackendImageUrl = (imageUrl) => {
    return `${baseUrl}/fetch-image?url=${encodeURIComponent(imageUrl)}`
};

export const fetchAccessToken = async () => {
    try {
        const response = await axios.get(`${baseUrl}/get-dropbox-token`);
        console.log("dropbox token response", response, getCurrentTimestamp());
        return response.data.accessToken
    } catch (error) {
        console.error('Error getting Dropbox access token:', error);
    }
}

export const handleDownloadFromDropbox = async (dropboxUrl) => {
    try {
        const response = await axios.post(`${baseUrl}/download-from-dropbox`, { dropboxUrl });
        console.log("Download From Dropbox", response, getCurrentTimestamp());
    } catch (error) {
        console.error('Error downloading file from Dropbox:', error);
    }
};

export const handleUserCredits = async (userId) => {
    console.log("userID:", userId);

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/update-credits`, { userId });
        console.log("Credits Updated...", response, getCurrentTimestamp());
        return response

    } catch (error) {
        console.log(error);
    }
}

export const getUserData = async (userId) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/get-user/${userId}`);
        return response.data.user

    } catch (error) {
        console.log(error);
    }
};

export const getPlans = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/plans`);
        return response.data

    } catch (error) {
        console.log(error);
    }
};

export const getUserRegion = async () => {
    try {
        const response = await axios.get(`${baseUrl}/userRegion`);
        return response.data
    } catch (error) {
        console.error('Error getting user region:', error);
    }
}